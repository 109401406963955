import {useCallback, useMemo} from 'react';

import {Checkbox, FormControlLabel} from '@mui/material';
import Stack from '@mui/material/Stack';
import {Record} from '@phosphor-icons/react';

import {addDays} from 'date-fns';
import {useLocalStorage} from 'react-use';
import useSWR from 'swr';

import CancelRecordingAction from '@src/components/actions/CancelRecordingAction';
import LoadingView from '@src/components/layouts/core/app/LoadingView';
import DataGridZeroState from '@src/components/legacy/Navigator/DataGridZeroState';
import MeetingCard from '@src/components/sessions/MeetingCard';
import {useCMSClient} from '@src/hooks/useCMSClient';
import useOrganizationEventListener from '@src/hooks/useOrganizationEventListener';
import useUser from '@src/hooks/useUser';

const getKey = (userUid?: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return {
    filters: {
      meeting: {
        and: [
          {or: [{recallBotStatus: {ne: 'done'}}, {recallBotStatus: {eq: null}}]},
          {
            scheduledAt: {
              gte: today,
              lte: addDays(today, 8),
            },
            ...(userUid
              ? {
                  scheduledBy: {
                    uid: {
                      eq: userUid,
                    },
                  },
                }
              : {}),
          },
        ],
      },
    },
    sort: ['meeting.scheduledAt:ASC'],
    query: 'getSessionAssets',
    pagination: {
      limit: 5,
    },
  };
};

const UpcomingRecordings = () => {
  const CMSClient = useCMSClient();
  const {user} = useUser();
  const [scheduledByMe, setShowScheduledByMe] = useLocalStorage(
    'widget-recordings-scheduled-by-me',
    true
  );

  const swrKey = useMemo(() => {
    return getKey(scheduledByMe ? user.sub : undefined);
  }, [user, scheduledByMe]);

  const {
    data: assets,
    isLoading,
    mutate,
  } = useSWR(swrKey, async (key) => {
    const result = await CMSClient.getSessionAssets(key);
    return result?.assets?.data ?? [];
  });

  const removeScheduledBot = async (id: string) => {
    await CMSClient.deleteMeeting({id: id});
    mutate();
  };

  const getActions = useCallback((asset) => {
    const actions: JSX.Element[] = [];

    actions.push(
      <CancelRecordingAction
        key="cancel-recording"
        id={asset.attributes.meeting.data.id}
        removeScheduledBot={removeScheduledBot}
        showInMenu
      />
    );

    return actions;
  }, []);

  useOrganizationEventListener(({event}) => {
    if (event.type === 'session-changed' && event.data.userEmail === user.email) {
      mutate();
    }
  });

  if (isLoading) {
    return <LoadingView />;
  }

  if (!assets?.length) {
    return (
      <DataGridZeroState
        icon={<Record />}
        message="Schedule some meetings"
        title="Nothing to Show"
      />
    );
  }

  return (
    <Stack gap={2}>
      <FormControlLabel
        control={
          <Checkbox
            checked={scheduledByMe}
            onChange={(event) => {
              return setShowScheduledByMe(event.target.checked);
            }}
          />
        }
        label="Only Scheduled By Me"
      />
      {assets?.map((asset) => {
        const link = `/project/${asset.attributes.project.data.attributes.uid}/asset/${asset.attributes.uid}`;

        return (
          <MeetingCard
            key={asset.id}
            actions={getActions(asset)}
            asset={asset}
            link={link}
          />
        );
      })}
    </Stack>
  );
};

export default UpcomingRecordings;
