import {BaseUserMeta, JsonObject, User} from '@liveblocks/client';
import {Avatar, AvatarGroup, Box} from '@mui/material';
import {Editor} from '@tiptap/react';

import FormattingToolbarButtons from './FormattingToolbarButtons';

type Props = {
  editor: Editor;
  isDisabled?: boolean;
  beforeFormattingOptions?: JSX.Element[];
  afterFormattingOptions?: JSX.Element[];
  users: readonly User<JsonObject, BaseUserMeta>[];
};

const EditorToolbar = ({
  afterFormattingOptions = [],
  beforeFormattingOptions = [],
  editor,
  isDisabled = false,
  users,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexWrap: 'wrap',
          flex: '1',
          minWidth: 0,
          padding: 0.5,
        }}
      >
        {...beforeFormattingOptions}
        <FormattingToolbarButtons editor={editor} isDisabled={isDisabled} />
        {...afterFormattingOptions}
      </Box>

      <AvatarGroup sx={{flex: 'none', paddingRight: 1}}>
        {users.map(({connectionId, info}) => {
          return (
            <Avatar
              key={connectionId}
              alt={info?.name}
              src={info?.avatar}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          );
        })}
      </AvatarGroup>
    </Box>
  );
};

export default EditorToolbar;
